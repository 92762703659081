<template>
  <v-alert v-if="response.message" dense outlined :type="response.type">
    {{ response.message }}
  </v-alert>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters({
            response: "getResponseMessage"
        })
    }
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="form-template">
    <v-container>
      <v-row>
        <v-col cols="12" lg="12" sm="12">
          <Alert></Alert>
        </v-col>
      </v-row>
      <slot name="form-body"></slot>
    </v-container>
  </div>
</template>

<script>
import Alert from "@/components/Alert.vue";
export default {
  components: {
    Alert,
  },
};
</script>

<style lang="scss" scoped></style>
